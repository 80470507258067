@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components{

  .btn-xl
  {
    @apply bg-[#01B289] font-semibold text-white text-sm py-4 px-7 inline-block rounded-[5px] tracking-[-.15px]  hover:bg-[#01B289] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 border border-transparent; 
  }
  

  .Toastify .Toastify__toast-container .info-toast,.Toastify__toast--info,.Toastify__toast
  {
    @apply bg-[#2ac08b] font-semibold text-center text-xs text-white border-0 max-w-[240px] min-h-[48px] rounded-md;
  }


  .Toastify .Toastify__toast-container .error-toast,.Toastify__toast--error,.Toastify__toast
  {
    @apply bg-[#ff5858] font-semibold text-center text-xs text-white border-0 max-w-[240px] min-h-[48px] rounded-md;
  }
  
  .btn-md
  {
    /* color: #2ac08b; */
    /* text-[#073526] */
    @apply bg-[#2ac08b] font-semibold text-[#fff] transition-all text-xs py-2 px-3 md:py-2 md:px-4 inline-block rounded-[4px] tracking-[-.15px]  hover:bg-[#59d097] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 border border-transparent
  }


  .btn-md:hover
  {
    /* @apply shadow-green-100 */
  }

  .btn-md-inverted
  {
    /* color: #2ac08b; */
    /* text-[#073526] */
    @apply border border-[#2ac08b] font-semibold text-[#fff] transition-all text-xs py-2 px-3 md:py-2 md:px-4 inline-block rounded-[4px] tracking-[-.15px]  hover:bg-[#59d097] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 border border-transparent
  }
  .btn-md-inverted:hover
  {
    /* @apply shadow-green-100 */
  }

  .btn-xs-inverted
  {
    /* color: #2ac08b; */
    /* text-[#073526] */
    @apply border border-[#2ac08b] font-semibold text-[#2ac08b] transition-all text-xs py-2 px-3 md:py-1 md:px-3 inline-block rounded-[2px] tracking-[-.15px]  hover:bg-[#59d097] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 
  }
  .btn-xs-inverted:hover
  {
    /* @apply shadow-green-100 */
  }
  .btn-xs
  {
    /* color: #2ac08b; */
    /* text-[#073526] */
    @apply border border-[#2ac08b] font-semibold text-[#2ac08b] transition-all text-xs py-1 px-2 md:py-1 md:px-2 inline-block rounded-[2px] tracking-[-.15px]  hover:bg-[#59d097] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-800 hover:text-white
  }
  .btn-xs:hover
  {
    /* @apply shadow-green-100 */
  }

  .btn-md-disabled
  {
    @apply bg-gray-50  font-medium text-gray-500 text-xs py-2 px-4 inline-block rounded-[4px] tracking-[-.15px]  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-1 transition-all dark:focus:ring-offset-gray-500 border border-gray-200
  }
  .btn-md-disabled:hover
  {

  }

  .btn-red
  {
    @apply border-[#ff5858] text-[#ff5858] hover:bg-[#ff5858];
  }

  label
  {
    @apply block mb-[2px] text-[12px] font-medium text-gray-400 tracking-[-.25px]
  }

  select,.text-input
  {
    @apply w-full h-auto border border-gray-200 bg-[#fcfcfc] text-[13px] px-4 py-2 rounded-[4px] transition-all  focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 focus:border-green-400 focus:outline-none focus:ring focus:ring-green-300 focus:ring-opacity-40 tracking-[-.15px] resize-none
  }

  .multi-selection__value-container
  {
    @apply !focus:ring-green-500 focus:ring-offset-1 focus:border-green-400 focus:outline-none focus:ring focus:ring-green-300 focus:ring-opacity-40
  }
  .multi-selection__input-container .multi-selection__input
  {
    @apply !focus:ring-0 focus:outline-none focus:outline-none focus:ring-transparent focus:border-green-500;
    outline: none!important;
    outline-offset: 0!important;
    outline-color: transparent!important;
    opacity: 0!important;
  }

  .multi-selection__input-container .multi-selection__multi-value
  {
    @apply !rounded-[24px]
  }

  input
  {
    @apply transition-all  focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1
  }


  .filter-select
  {
    @apply w-full h-auto border-2  bg-[#e3e3e3] focus:bg-[transparent] active:bg-[transparent] text-[13px] px-4 py-2 rounded-[4px] transition-all   tracking-[-.15px] focus:ring-offset-0 focus:ring-0
  }


  #react-select-3-input
  {
    @apply focus:ring-offset-0 focus:ring-0 
  }
  
  .filter-select .filter-select__control,.css-13cymwt-control
  {
    /* @apply py-[5px] border border-r rounded-[4px] bg-[#fafafa] border-[#d3d3d3] hover:border-[#e3e3e3] */
    @apply w-full h-auto border border-gray-200 bg-[#fcfcfc] text-[13px] px-0 py-0 rounded-[4px] transition-all  focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-1 focus:border-green-400 focus:outline-none focus:ring focus:ring-green-300 focus:ring-opacity-40 tracking-[-.15px]
  }

}

/* Define the animation */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


button:disabled
{
  background-color: #d3d3d3;
}
button:disabled:hover
{
  background-color: #d3d3d3;
}
/* Apply the animation to the loader element */
.loader {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Customize loader appearance */
  border-top: 4px solid #2ac08b; /* Customize loader appearance */
  border-left: 4px solid #2ac08b; /* Customize loader appearance */
  border-radius: 50%;
  width: 34px;
  height: 34px;
  animation: rotate 1s linear infinite; /* Apply the animation */
}

/* Optional: Center the loader on the screen */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}



.react-datepicker__input-container
{
  height: 100%;
}

.filter-select__menu
{
  margin: 0;
}
.menu-item-active::after
{
  position: absolute;
  left: 36px;
  top: 48px;
  width: 1px;
  transition: all .35s ease-in-out;
  /* height:65%; */
  height:calc(100% - 48px - 24px);;
  background-color: #a3a3a3;
  content: '';
}

.menu-sub-item::after
{
  position: absolute;
  left: 36px;
  top: 50%;
  width: 12px;
  height: 1px;
  background-color: #a3a3a3;
  content: '';
}
.menu-sub-active
{
  /* background-color: #a3a3a3; */
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F5F5;
}

*
{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: 'Inter',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table
{
  /* overflow: hidden; */
  table-layout: fixed!important;
}
thead tr
{
  background-color: #fafafa;
  border-bottom: 1px solid #fafafa;
}
/* thead tr td
{
} */
thead tr td,thead tr th
{
  padding: 12px 16px;
  font-size: .75rem;
  font-weight: 500;
  color: #757575;
  letter-spacing: -.15px;
  overflow: hidden; /* Optional: Hide overflowing content */
  text-overflow: ellipsis; /* Optional: Show ellipsis for overflowing content */
  white-space: nowrap; /* Optional: Prevent line breaks */
}

tbody tr
{
  border-bottom: 1px solid #fefefe;
}
tbody tr:hover
{
  background-color: #fafafa;
  cursor: pointer;
}
tbody tr td
{
  padding: 16px 16px;
}

tbody tr td
{
  /* font-size: .85rem;
  font-weight: 500;
  color: #151515;
  letter-spacing: -.15px; */
}

table tr td,
table tr th {
  display: flex;
  flex: 1;
  padding: 12px 32px;
  align-items:center;
  /* justify-content:center; */
}

table {
  display: flex;
  flex-direction: column;
  height: 100%;
}
table thead,
table tbody {
  display: block;
}
table thead {
  margin-right: 0px;
}
table thead tr{
  border-bottom: 1px solid #efefef;
}
table tbody {
  flex: 1;
  overflow-y: scroll;
}
table tbody tr{
  border-bottom: 1px solid #f3f3f3;
}
table tr {
  width: 100%;
  text-align: left;
  display: flex;
}
table tr td,
table tr th {
  display: flex;
  flex: 1;
  align-items:center;
  /* justify-content:center; */
}

.tab-group-active
{
}
.tab-group-active::after
{
  position: absolute;
  content: '';
  bottom: 0;
  width: 100%;
  background-color: #2ac08b;
  height: 3px;
  left: 0;
}